<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Students
                        <v-spacer></v-spacer>
                        <v-btn outlined ma-0 small @click="form.dialog = true,fetchRoles()">Admin new student</v-btn>
                    </v-card-title>
                    <v-card>
                        <v-card-title class="title">
                            <v-flex xs3>
                                <v-select outlined dense :items="batches" class="pa-0" label="Select batch" v-model="batch"/>
                            </v-flex>
                            <v-flex xs9>
                                <v-text-field
                                    outlined dense
                                    :disabled="batches.length<1"
                                    hint="At least 3 characters"
                                    flat
                                    color="primary"
                                    class="pa-0"
                                    label="Student Code or Name"
                                    v-model="search"
                                    @input="searchStudents"
                                    clearable/>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table
                            :headers="headers"
                            hide-default-footer
                            :items="form.items.data"
                            :search="search"
                            :options.sync="pagination"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left"><big><strong>{{ item.name }}</strong></big><br>
                                    <span><span>{{item.personal.gender}}, {{item.personal.dob}}</span>
                                    </span>
                                </td>
                                <td class="text-xs-left"><strong>{{ item.grade }}</strong>&nbsp; <sup>{{item.section}}</sup>
                                </td>
                                <td class="text-xs-left">{{ item.roll }}</td>
                                <td class="text-xs-left">
                                </td>
                                <td class="text-xs-right">
                                    <view-button permission="view-grade" @click.native="viewGrade(item.id)"/>
                                    <edit-button permission="edit-grade" @agree="fetchRoles(),update(item)"/>
                                    <delete-button permission="delete-grade" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer/>
                        <v-pagination v-model="pagination.page" :length="form.items.meta.last_page"></v-pagination>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({}, '/api/student'),
            search: null,
            pagination: {
                rowsPerPage: 10
            },
            headers: [
                {text: '#', align: 'left', value: 'roll', width: 10, sortable: false},
                {text: 'Photo', align: 'left', value: 'roll', width: 100, sortable: false},
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Date of birth', align: 'left', value: 'dob', sortable: false, width: 100},
                {text: 'Gender', align: 'left', value: 'gender', sortable: false, width: 100},
                {text: 'Grade', align: 'left', value: 'grade', sortable: false, width: 100},
                {text: 'Roll', align: 'left', value: 'roll', width: '10%'},
                {text: 'Action', align: 'left', value: 'roll', sortable: false, width: 100},
            ],
            batches: [],
            batch: '',
        }),
        watch: {
            'pagination': function () {
                this.get();
            },
        },
        mounted() {
            this.getBatches();
        },
        computed: {},
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
					if(typeof json[key] == 'object') {
						let vals = [...json[key]];
                        if(vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
					}
					return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
				}).join('&');
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },
            searchStudents: function (v) {
                if (v.length > 2 || !v.length) this.get()
            },
            getBatches() {
                this.$rest.get('/api/batch').then(({data}) => {
                    this.batches = data.data.map(item => {
                        return {value: item.id, text: item.name};
                    });
                })
            }
        }
    }
</script>

<style lang="scss">
    .user_add_form {
        .v-chip, .v-chip .v-chip__content {
            border-radius: 2px;
            color: white;
        }
    }
</style>